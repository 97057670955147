import React, { FC } from "react";

import { EntryPortal, Page } from "@highmountainlabs/arclight-ui";

const Portal: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [index, setIndex] = React.useState(0);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].portalpagetheme`,
    null
  );
  return D && fns && Settings ? (
    <Page fns={fns}>
      <EntryPortal
        rows={1}
        baseBackground={Settings.img}
        cards={[
          {
            backgroundHover: Settings.leagueoflegendsimage,
            hoverComponent: (
              <img
                src={Settings.lolemblem}
                className={`w-32 lg:w-64 object-cover`}
              />
            ),
            bgImg: Settings.leagueoflegendsimage,
            subText: "League of Legends",
            onClick: () => fns.route("/", "lol"),
            locked: Settings.lollocked,
          },
        ]}
      />
    </Page>
  ) : null;
};

export default Portal;
