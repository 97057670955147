import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import {
  Page,
  ProfilePage,
  HeroPanel,
  Card,
  FadeDivider,
} from "@highmountainlabs/arclight-ui";

const POSITIONS: any = {
  top: "65d208ac5d136c5ed0dc58ed.png",
  jungle: "65d208b45d136c5ed0dc58fa.png",
  middle: "65d208bd5d136c5ed0dc5907.png",
  bottom: "65d208c65d136c5ed0dc5914.png",
  support: "65d208cc5d136c5ed0dc5921.png",
};

const Staff: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [league, setLeague] = React.useState<any>(null);
  const [Team, setTeam] = React.useState<any>(null);
  const [Summoners, setSummoners] = React.useState<any>(null);
  const C = (props) => {
    const [isHover, setIsHover] = React.useState(0);
    const name = props.member.name.replace("(M) ", "");
    console.log(props);
    return (
      <div className={`mr-6 md:mr-0`}>
        <Card
          line
          bgImg={
            props.member.img
              ? props.member.img
              : "http://highmountainlabs.io/cdn/arclight/media/summoner.jpg"
          }
          bodyComponent={
            <div className={`w-full h-full flex justify-start space-x-4 m-4`}>
              <img
                src={`${endpoint}/static/media/${POSITIONS[props.position]}`}
                className={`mx-0 md:mx-10 w-16 h-16 md:w-20 md:h-20 object-cover rounded-full bg-visibility-primary p-2 border-2 border-background-tertiary`}
              />
              <div
                className={`text-sm md:text-xl flex flex-col justify-center`}
              >
                {name}
              </div>
              <div className={`flex justify-end text-sm md:text-lg mx-10 grow`}>
                <div className={`flex flex-col justify-center space-y-3`}>
                  {[
                    { route: `https://www.op.gg/summoners/na/${name}` },
                    { route: `https://u.gg/lol/profile/na1/${name}/overview` },
                  ].map((obj: any, i) => (
                    <div
                      onMouseEnter={() => setIsHover(i + 1)}
                      onMouseLeave={() => setIsHover(0)}
                      style={{
                        color: isHover === i + 1 ? "purple" : undefined,
                      }}
                      onClick={() => fns.routeExternal(obj.route)}
                    >
                      <div className={`hidden xl:block`}>{obj.route}</div>
                      <div className={`xl:hidden`}>
                        Link - {i ? "u.gg" : "op.gg"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
          onClick={() => null}
        />
      </div>
    );
  };
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolteampagesettings`,
    null
  );
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolteam && !Team) {
      fns.calls.getrecords_lolteam({
        search: { _id: fns.readState().query.id.toString() },
      });
    }
  }, [fns.calls]);
  React.useEffect(() => {
    if (D && D.getrecords_lolteam && D.getrecords_lolteam.init && !Team) {
      const T = D.getrecords_lolteam.init.records[0];
      setTeam(T);
      fns.calls.getrecords_lolleague({
        index: "league",
        search: { _id: T.league },
      });
      fns.calls[`getrecords_(M) summoner`]({
        search: {
          _id: [
            "member_top",
            "member_jungle",
            "member_middle",
            "member_bottom",
            "member_support",
          ]
            .filter((s: string) => T[s])
            .map((s: string) => T[s])
            .concat(
              T.member_subs ? T.member_subs.filter((s: string) => s) : []
            ),
        },
      });
    }
    if (Team && !Summoners && D[`getrecords_(M) summoner`]?.init) {
      const _ = {};
      D[`getrecords_(M) summoner`].init.records.map((S: any) => (_[S._id] = S));
      setSummoners(_);
    }
    if (D && D.getrecords_lolleague && D.getrecords_lolleague.league)
      setLeague(D.getrecords_lolleague.league.records[0]);
  }, [D]);
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolleague && !league) {
      const q = fns.readState().query;
      fns.calls.getrecords_lolleague({
        index: "league",
        search: { name: q.id },
      });
    }
  }, [fns.calls]);
  return D && fns && Settings && Team && Summoners && league ? (
    <Page
      key={fns.readState().query.id.toString()}
      fns={fns}
      backgroundImage={
        league.img
          ? {
              //src: Settings.backgroundimage,
              src: league.img,
              opacity,
            }
          : undefined
      }
    >
      <div className={`absolute top-0 w-full`}>
        <Header D={D} fns={fns} endpoint={endpoint} />
      </div>
      <div className={`relative`}>
        <FadeDivider img={Team.img} fadeOpacity={0.15} />
        <img
          src={Team.logo ? Team.logo : league.logo}
          className={`absolute mt-10 w-44 lg:w-72 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-cover rounded-full bg-visibility-primary p-2 border-2 border-background-tertiary`}
        />
      </div>
      <div
        className={`font-primary text-text-primary text-4xl text-center mb-16 md:mb-20`}
      >
        {Team.name}
      </div>
      <div className={`w-full md:w-4/5 md:m-auto`}>
        <div className={`w-full`}>
          <div
            className={`ml-4 md:ml-0 text-lg md:text-2xl text-text-primary font-primary`}
          >
            Starting Members
          </div>
          {[
            "member_top",
            "member_jungle",
            "member_middle",
            "member_bottom",
            "member_support",
          ]
            .filter((s: string) => Team[s] && Summoners[Team[s]])
            .map((s: string) => (
              <C position={s.split("_")[1]} member={Summoners[Team[s]]} />
            ))}
          <div className={`h-12 md:h-24`} />
          {Team.member_subs &&
          Team.member_subs.filter((s: string) => Summoners[s]).length ? (
            <div
              className={`ml-4 md:ml-0 text-lg md:text-2xl text-text-primary font-primary`}
            >
              Substitutions
            </div>
          ) : null}
          {Team.member_subs
            ? Team.member_subs
                .filter((s: string) => Summoners[s])
                .map((s: string) => (
                  <C position={"sub"} member={Summoners[s]} />
                ))
            : null}
        </div>
      </div>
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Staff;
