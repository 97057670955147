import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";

import {
  ListPanel,
  Page,
  PageTitle,
  HeroPanel,
} from "@highmountainlabs/arclight-ui";

const Home: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [hItem, setHItem] = React.useState(0);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolcommunitypagesettings`,
    null
  );
  return D && fns.calls && Settings ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.backgroundimage,
        opacity,
      }}
    >
      <Header D={D} fns={fns} endpoint={endpoint} />
      <PageTitle
        orientation={"center"}
        text={"Community & Leaderboards"}
        fns={fns}
        img={Settings.titleimage}
        bg={"http://localhost:7000/static/media/background2.mp4"}
        bgOffset={50}
      />
      <HeroPanel
        small
        cards={[
          {
            hoverComponent: (
              <div>
                <div className={`text-md`}>
                  Individual player progression and achievements spanning all
                  TES seasons
                </div>
              </div>
            ),
            bgImg: Settings.legendsimage,
            subText: "Summoners",
          },
          {
            hoverComponent: (
              <div>
                <div className={`text-md`}>
                  Team progression and achievements spanning all TES seasons
                </div>
              </div>
            ),
            bgImg: Settings.dynastiesimage,
            subText: "Orgs",
          },
        ].map((el: any, i: number) => ({
          ...el,
          onClick: () => setHItem(i),
          active: hItem === i,
        }))}
      />
      <ListPanel
        controls={[
          {
            icon: "diamond",
            text: "name",
            key: "name",
          },
        ]}
        key={hItem}
        Request={{
          index: "community",
          type: hItem ? `(M) summoner` : `(M) summoner`,
          search: { limit: 32 },
        }}
        fns={fns}
        D={D}
        card={(c: any) => ({
          img: c.img,
          subtext: hItem ? c.name : c.name,
          onClick: () => null,
        })}
        line={false}
        constrain
      />
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Home;
