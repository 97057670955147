import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import {
  ListPanel,
  Page,
  PageTitle,
  HeroPanel,
} from "@highmountainlabs/arclight-ui";

const Statistics: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [hItem, setHItem] = React.useState(1);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolstatspagesettings`,
    null
  );
  return D && fns.calls && Settings ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.backgroundimage,
        opacity,
      }}
    >
      <Header D={D} fns={fns} endpoint={endpoint} />
      {/* <PageTitle
        orientation={"center"}
        text={"League Statistics"}
        fns={fns}
        img={Settings.titleimage}
        bg={"http://localhost:7000/static/media/background2.mp4"}
        bgOffset={50}
      /> */}
      <HeroPanel
        small
        cards={fns
          .e(D, `D.getrecords_lolleague.lolleague`, [])
          .map((league: { [key: string]: any }, i) => ({
            active: hItem === i + 1,
            locked: league.locked,
            hoverComponent: (
              <div>
                <div className={`text-md`}>{league.name} Teams</div>
              </div>
            ),
            bgImg: league.img,
            subText: league.name,
            onClick: () => setHItem(i + 1),
          }))}
      />
      <div className={`m-0 md:m-20 my-0 bg-white overflow-hidden rounded`}>
        <iframe
          src={`https://semzzz.web.app`}
          width={`100%`}
          height={`1550px`}
        />
      </div>
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Statistics;
