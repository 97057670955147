import React, { FC } from "react";
import {
  SlantedDivider,
  HeroPanel,
  MissionStatement,
  CompassViewer,
  Button,
  Page,
  FadeDivider,
  PageTitle,
} from "@highmountainlabs/arclight-ui";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";

const Home: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  const [leagueIndex, setLeagueIndex] = React.useState(0);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [leagues, setLeagues] = React.useState<any>([]);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolhomepagesettings`,
    null
  );
  const Settings2 = fns.e(D, `D.getrecords_settings.init.records[0]`, null);
  const H = JSON.parse(
    fns.e(D, `D.getrecords_settings.init.records[0].welcometext`, "{}")
  ).leagueoflegends;
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolleague && !leagues.length)
      fns.calls.getrecords_lolleague({
        search: {},
      });
  }, [fns.calls]);
  React.useEffect(() => {
    if (fns.e(D, "D.getrecords_lolleague.init.records", false))
      setLeagues(D.getrecords_lolleague.init.records);
  }, [D]);
  return D && fns.calls && Settings && H ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.backgroundimage,
        opacity,
      }}
    >
      <Header main D={D} fns={fns} endpoint={endpoint} />
      <div className={`h-24 md:h-96`} />
      <div
        className={`mx-10 md:mx-60 h-96 lg:h-[700px] mt-40 md:mb-40 md:mt-0 rounded overflow-hidden`}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://player.twitch.tv/?autoplay=false&channel=titanesportz&parent=titanesports.org&parent=lol.titanesports.org"
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        />
      </div>
      {/* <CompassViewer
        bgCompassElement={Settings.diamondvideo}
        defaultSelection={1}
        items={[
          {
            bgImg: Settings.diamondimagetop,
            element: (
              <MissionStatement
                bgImg={Settings.diamondimagetop}
                title={H.welcome.title}
                Message={
                  <table className={``}>
                    <tbody>
                      <tr>
                        <td className={`hidden sm:block`}>
                          <img className={`w-[600px]`} src={Settings.emblem} />
                        </td>
                        <td>
                          <div className={`pl-0 md:pl-10`}>
                            {H.welcome.text}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              />
            ),
          },
          {
            bgImg: `${endpoint}/static/media/discord.png`,
            element: (
              <MissionStatement
                bgImg={`${endpoint}/static/media/discord.jpg`}
                title={H.discord.title}
                Message={<div>{H.discord.text}</div>}
                button={
                  <Button
                    label={"Titan Esports Discord"}
                    idleIcon={"discord"}
                    type={"button"}
                    size={"normal"}
                    animation={true}
                    onClick={(status: any) =>
                      fns.routeExternal(Settings2.discordlink)
                    }
                  />
                }
              />
            ),
          },
          {
            bgImg: `${endpoint}/static/media/twitch.png`,
            element: (
              <div>
                <MissionStatement
                  bgImg={`${endpoint}/static/media/twitchtv.jpg`}
                  title={H.twitch.title}
                  Message={<div>{H.twitch.text}</div>}
                  button={
                    <Button
                      label={"Titan Esports Twitch.tv"}
                      idleIcon={"twitch"}
                      type={"button"}
                      size={"normal"}
                      animation={true}
                      onClick={(status: any) =>
                        fns.routeExternal(Settings2.twitchlink)
                      }
                    />
                  }
                />
              </div>
            ),
          },
          {
            bgImg: `${endpoint}/static/media/youtube.jpg`,
            element: (
              <MissionStatement
                bgImg={`${endpoint}/static/media/youtube.jpg`}
                title={H.youtube.title}
                Message={<div>{H.youtube.text}</div>}
                button={
                  <Button
                    label={"Titan Esports Youtube"}
                    idleIcon={"youtube"}
                    type={"button"}
                    size={"normal"}
                    animation={true}
                    onClick={(status: any) =>
                      fns.routeExternal(Settings2.youtubelink)
                    }
                  />
                }
              />
            ),
          },
        ]}
      /> */}
      <MissionStatement
        title={"Titan Esports"}
        Message={
          <div>
            Welcome to Titan Esports, the ultimate destination for amateur
            e-sports enthusiasts! Immerse yourself in the world of competitive
            gaming with our three thrilling leagues: Divinity, Conqueror, and
            Vanquisher. Whether you're a seasoned player or just starting out,
            Titan Esports offers a platform for players of all skill levels to
            showcase their talents and passion for gaming. Join the Divinity
            League for the ultimate challenge, where only the best of the best
            compete for glory and recognition. Rise through the ranks in the
            Conqueror League, facing intense competition and reaping greater
            rewards. And in the Vanquisher League, test your skills against
            like-minded competitors as you begin your journey towards e-sports
            excellence. Get ready to experience adrenaline-pumping matches,
            forge new friendships, and embark on a journey of e-sports
            greatness. Join us at Titan Esports and be part of a thriving
            community that celebrates the spirit of competition and camaraderie.
          </div>
        }
      />
      {/* <PageTitle
        orientation={"center"}
        text={"Leagues"}
        fns={fns}
        img={Settings.titleimage}
        bg={"http://localhost:7000/static/media/background2.mp4"}
        bgOffset={50}
      /> */}
      <div className={`mx-10`}>
        <HeroPanel
          rows={1}
          index={leagueIndex}
          pageCallback={(n: number) => setLeagueIndex(n)}
          cards={leagues.map((league: { [key: string]: any }) => ({
            // locked: league.locked,
            hoverComponent: (
              <div>
                <img
                  src={league.logo}
                  className={`w-24 lg:w-64 object-cover m-auto`}
                />
                <br />
                <div>{JSON.parse(league.description).skillcap}</div>
              </div>
            ),
            bgImg: league.img,
            subText: league.name,
            onClick: () => fns.route(`/league?id=${league.name}`),
          }))}
        />
      </div>
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Home;
