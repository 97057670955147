import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import { Page, AuthPage } from "@highmountainlabs/arclight-ui";

const Staff: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolloginpagesettings`,
    null
  );
  return D && fns.calls && Settings ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.backgroundimage,
        opacity,
      }}
    >
      <Header D={D} fns={fns} endpoint={endpoint} />
      <AuthPage
        redirect={"/"}
        D={D}
        fns={fns}
        authBackgroundImage={Settings.cardimage}
        OATHOnly={true}
        OATH={[
          {
            type: "discord",
            onClick: () =>
              fns.calls && fns.calls.DiscordOATH2
                ? fns.calls.DiscordOATH2({
                    domain: fns.readState().subdomain,
                  })
                : null,
          },
        ]}
      />
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Staff;
