import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "@highmountainlabs/arclight-ui";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App
      nopage={"http://highmountainlabs.io/cdn/arclight/media/404.jpg"}
      socketEndpoint={`https://titanesports.org:7000`}
      loader={`https://titanesports.org:7000/static/media/6580d03bdb096d04c7ad7cf2.png`}
      background={{
        src: `https://titanesports.org:7000/static/media/65810e54db096d04c7ad86b2.png`,
        opacity: 0.05,
      }}
      pages={{
        lol: {
          Home: { route: "/", component: require("./pages/Home") },
          Staff: { route: "/staff", component: require("./pages/Staff") },
          Community: {
            route: "/community",
            component: require("./pages/Community"),
          },
          Dynasty: { route: "/dynasty", component: require("./pages/Dynasty") },
          Applications: {
            route: "/applications",
            component: require("./pages/Applications"),
          },
          Login: { route: "/login", component: require("./pages/Login") },
          Profile: { route: "/profile", component: require("./pages/Profile") },
          Statistics: {
            route: "/statistics",
            component: require("./pages/Statistics"),
          },
          Team: { route: "/team", component: require("./pages/Team") },
          //Content: { route: "/content", component: require("./pages/Content") },
          League: { route: "/league", component: require("./pages/League") },
          Leagues: { route: "/leagues", component: require("./pages/Leagues") },
          Article: {
            route: "/article",
            component: require("./pages/Article"),
          },
        },
        admin: {
          Home: {
            route: "/",
            backgroundImage:
              "https://titanesports.org:7000/static/media/65810e54db096d04c7ad86b2.png",
            authBackgroundImage:
              "https://titanesports.org:7000/static/media/65810fb0db096d04c7ad8812.png",
            noSelect:
              "https://titanesports.org:7000/static/media/6580d03bdb096d04c7ad7cf2.png",
          },
        },
        _root_: {
          Home: { route: "/", component: require("./pages/Portal") },
        },
      }}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
