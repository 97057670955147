import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import {
  Page,
  AuthPage,
  HeroPanel,
  EntryPortal,
} from "@highmountainlabs/arclight-ui";

const Staff: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [leagues, setLeagues] = React.useState<any>([]);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolhomepagesettings`,
    null
  );
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolleague) {
      fns.calls.getrecords_lolleague();
    }
  }, [fns.calls]);
  React.useEffect(() => {
    if (D && D.getrecords_lolleague && D.getrecords_lolleague.init)
      setLeagues(D.getrecords_lolleague.init.records);
  }, [D]);
  return D && fns.calls && Settings ? (
    <Page fns={fns}>
      <div className={`absolute top-0 w-full`}>
        <Header D={D} fns={fns} endpoint={endpoint} />
      </div>
      <EntryPortal
        rows={1}
        baseBackground={Settings.backgroundimage}
        cards={leagues.map((League: any) => ({
          backgroundHover: League.img,
          hoverComponent: (
            <img src={League.logo} className={`w-32 lg:w-64 object-cover`} />
          ),
          bgImg: League.img,
          subText: League.name,
          onClick: () => fns.route(`/league?id=${League.name}`),
        }))}
      />
      <div className={`absolute bottom-0 w-full`}>
        <Footer fns={fns} endpoint={endpoint} />
      </div>
    </Page>
  ) : null;
};

export default Staff;
