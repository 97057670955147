import React, { FC } from "react";
import { Header } from "@highmountainlabs/arclight-ui";

const _HEADER_ITEMS = {
  home: {
    text: "Home",
    icon: "house",
    route: "/",
  },
  leagues: {
    text: "Leagues",
    icon: "folder-tree",
    route: "/leagues",
  },
  statistics: {
    text: "Statistics",
    icon: "chart-line",
    route: "/statistics",
  },
  // content: {
  //   text: "Content",
  //   icon: "microphone",
  //   route: "/content",
  // },
  staff: {
    text: "Staff",
    icon: "person",
    route: "/staff",
  },
  // applications: {
  //   text: "Applications",
  //   icon: "highlighter",
  //   route: "/applications",
  // },
  // auth: {
  //   login: {
  //     text: "Sign-In",
  //     icon: "right-to-bracket",
  //     route: "/login",
  //   },
  //   logout: {
  //     text: "Sign-Out",
  //     icon: "right-from-bracket",
  //     route: "/",
  //   },
  // },
};

const H: FC<{
  fns: {
    [key: string]: any;
  };
  D: {
    [key: string]: any;
  };
  main?: boolean;
  endpoint?: string;
}> = ({ D, fns, main, endpoint }) => {
  const Settings = fns.e(D, `D.getrecords_settings.init.records[0]`, null);
  return main && Settings ? (
    <Header
      fns={fns}
      logo={{
        src: `https://titanesports.org:7000/static/media/6580d03bdb096d04c7ad7cf2.png`,
        route: "/",
      }}
      linksLeft={[
        {
          route: _HEADER_ITEMS.home.route,
          icon: _HEADER_ITEMS.home.icon,
          text: _HEADER_ITEMS.home.text,
        },
        {
          route: _HEADER_ITEMS.statistics.route,
          icon: _HEADER_ITEMS.statistics.icon,
          text: _HEADER_ITEMS.statistics.text,
        },
      ]}
      linksRight={[
        // {
        //   route: _HEADER_ITEMS.content.route,
        //   icon: _HEADER_ITEMS.content.icon,
        //   text: _HEADER_ITEMS.content.text,
        // },
        {
          route: _HEADER_ITEMS.leagues.route,
          icon: _HEADER_ITEMS.leagues.icon,
          text: _HEADER_ITEMS.leagues.text,
        },
        {
          route: _HEADER_ITEMS.staff.route,
          icon: _HEADER_ITEMS.staff.icon,
          text: _HEADER_ITEMS.staff.text,
        },
        // {
        //   route: _HEADER_ITEMS.applications.route,
        //   icon: _HEADER_ITEMS.applications.icon,
        //   text: _HEADER_ITEMS.applications.text,
        // },
        // fns.readToken()._token
        //   ? {
        //       icon: _HEADER_ITEMS.auth.logout.icon,
        //       route: _HEADER_ITEMS.auth.logout.route,
        //       text: _HEADER_ITEMS.auth.logout.text,
        //       onClick: () => fns.writeToken(),
        //     }
        //   : {
        //       icon: _HEADER_ITEMS.auth.login.icon,
        //       route: _HEADER_ITEMS.auth.login.route,
        //       text: _HEADER_ITEMS.auth.login.text,
        //     },
      ]}
      socialMediaLeft={[
        {
          routeExternal: Settings.discordlink,
          icon: "discord",
        },
        {
          routeExternal: Settings.twitchlink,
          icon: "twitch",
        },
        {
          routeExternal: Settings.twitterlink,
          icon: "twitter",
        },
      ]}
      socialMediaRight={[
        {
          routeExternal: Settings.redditlink,
          icon: "reddit",
        },
        {
          routeExternal: Settings.youtubelink,
          icon: "youtube",
        },
        {
          routeExternal: Settings.facebooklink,
          icon: "facebook",
        },
      ]}
    />
  ) : (
    <Header
      fns={fns}
      logo={{
        src: `https://titanesports.org:7000/static/media/6580d03bdb096d04c7ad7cf2.png`,
        route: "/",
      }}
      links={[
        {
          route: _HEADER_ITEMS.home.route,
          icon: _HEADER_ITEMS.home.icon,
          text: _HEADER_ITEMS.home.text,
        },
        {
          route: _HEADER_ITEMS.statistics.route,
          icon: _HEADER_ITEMS.statistics.icon,
          text: _HEADER_ITEMS.statistics.text,
        },
        {
          route: _HEADER_ITEMS.leagues.route,
          icon: _HEADER_ITEMS.leagues.icon,
          text: _HEADER_ITEMS.leagues.text,
        },
        // {
        //   route: _HEADER_ITEMS.content.route,
        //   icon: _HEADER_ITEMS.content.icon,
        //   text: _HEADER_ITEMS.content.text,
        // },
        {
          route: _HEADER_ITEMS.staff.route,
          icon: _HEADER_ITEMS.staff.icon,
          text: _HEADER_ITEMS.staff.text,
        },
        // {
        //   route: _HEADER_ITEMS.applications.route,
        //   icon: _HEADER_ITEMS.applications.icon,
        //   text: _HEADER_ITEMS.applications.text,
        // },
        // fns.readToken()._token
        //   ? {
        //       icon: _HEADER_ITEMS.auth.logout.icon,
        //       route: _HEADER_ITEMS.auth.logout.route,
        //       text: _HEADER_ITEMS.auth.logout.text,
        //       onClick: () => fns.writeToken(),
        //     }
        //   : {
        //       icon: _HEADER_ITEMS.auth.login.icon,
        //       route: _HEADER_ITEMS.auth.login.route,
        //       text: _HEADER_ITEMS.auth.login.text,
        //     },
      ]}
      socialMedia={[
        {
          routeExternal: Settings.discordlink,
          icon: "discord",
        },
        {
          routeExternal: Settings.twitchlink,
          icon: "twitch",
        },
        {
          routeExternal: Settings.twitterlink,
          icon: "twitter",
        },
        {
          routeExternal: Settings.redditlink,
          icon: "reddit",
        },
        {
          routeExternal: Settings.youtubelink,
          icon: "youtube",
        },
        {
          routeExternal: Settings.facebooklink,
          icon: "facebook",
        },
      ]}
    />
  );
};

export default H;
