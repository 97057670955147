import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import { HeroPanel, Page } from "@highmountainlabs/arclight-ui";

import { useMediaQuery } from "react-responsive";

const Staff: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const mobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [hpIndex, setHpIndex] = React.useState({});
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const Categories = {},
    HeroPanels: any = [];
  fns
    .e(D, `D.getrecords_lolstaffmembers.init.records`, [])
    .map((member: { [key: string]: any }) => {
      if (!Categories[member.responsibility])
        Categories[member.responsibility] = {};
      Categories[member.responsibility][member.name] = member;
    });
  Object.keys(Categories).map((category) => {
    HeroPanels.push(
      <div className={`mt-32`}>
        <HeroPanel
          single={mobile && false}
          index={hpIndex[category] ? hpIndex[category] : 0}
          pageCallback={(n: number) =>
            setHpIndex({ ...hpIndex, [category]: n })
          }
          rows={1}
          title={category}
          small
          autoSort
          //@ts-ignore
          cards={Object.values(Categories[category]).map((member: any) => ({
            hoverComponent: (
              <div>
                <div className={`text-xl`}>{member.position}</div>
                <div className={`text-md`}>{member.duty}</div>
              </div>
            ),
            bgImg: member.img,
            subText: member.name,
            onClick: () => null,
          }))}
        />
      </div>
    );
  });
  React.useEffect(() => {
    if (
      fns.calls &&
      fns.calls.getformtemplates &&
      !D.getrecords_lolstaffmembers
    )
      fns.calls.getrecords_lolstaffmembers();
  }, [fns.calls]);
  const Settings = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolstaffpagesettings`,
    null
  );
  return D && fns.calls && Settings ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.backgroundimage,
        opacity,
      }}
    >
      <Header D={D} fns={fns} endpoint={endpoint} />
      <div className={`mx-10`}>{HeroPanels}</div>
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Staff;
