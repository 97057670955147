import React, { FC } from "react";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import { Page, ArticlePage } from "@highmountainlabs/arclight-ui";

const Article: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const [opacity, setOpacity] = React.useState(0.35);
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [article, setArticle] = React.useState<any>(null);
  const Settings = fns.e(D, `D.getrecords_settings.init.records[0]`, null);
  const Settings2 = fns.e(
    D,
    `D.getrecords_settings.init.records[0].lolwebtheme.lolcontentpagesettings`,
    null
  );
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolarticle && !article)
      fns.calls.getrecords_lolarticle({
        search: { _id: fns.readState().query.id },
      });
  }, [fns.calls]);
  React.useEffect(() => {
    if (D && D.getrecords_lolarticle && D.getrecords_lolarticle.init)
      setArticle(D.getrecords_lolarticle.init.records[0]);
  }, [D]);
  return D && D && Settings && Settings2 && article ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings2.articlepagebackgroundimage,
        opacity,
      }}
    >
      <Header D={D} fns={fns} endpoint={endpoint} />
      {/* <ArticlePage
        nopage={D.nopage}
        googledoc={article ? article.googledoc : null}
        author={article ? article.author.username : null}
        fns={fns}
        bannerImg={article ? article.img : null}
        className={`-mt-24 lg:-mt-44`}
        ruby={article ? article.displayicon : null}
      /> */}
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default Article;
