import React, { FC } from "react";

import { useMediaQuery } from "react-responsive";

import Header from "../../projectcomponents/header";
import Footer from "../../projectcomponents/footer";
import {
  Page,
  FadeDivider,
  MissionStatement,
  HeroPanel,
  ListPanel,
  TournamentBracket,
  Card,
  FontAwesome,
} from "@highmountainlabs/arclight-ui";

const League: FC<{
  fns: {
    [key: string]: any;
  };
  D: { [key: string]: any };
  endpoint?: string;
}> = ({ fns, D, endpoint }) => {
  const mobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [diamonPickerIndex, setDiamondPickerIndex] = React.useState(0);
  const [opacity, setOpacity] = React.useState(0.35);
  const C = (props) => {
    return (
      <div className={`mr-6 md:mr-0`}>
        <Card
          onClick={() =>
            fns.routeExternal(
              `${
                process.env.REACT_APP_ENVIRONMENT === "production"
                  ? `https://lol.titanesports.org`
                  : `http://lol.localhost:3000`
              }/team?id=${props.teamid}`
            )
          }
          line
          bgImg={props.img}
          bodyComponent={
            <div
              className={`w-full h-full flex justify-start space-x-4 m-4 relative md:text-xl`}
            >
              <table className={`w-full table-fixed `}>
                <tr>
                  <td className={`w-0 md:w-4 text-xl`}>{props.rank}</td>
                  <td className={`w-32 md:w-44`}>
                    <img
                      src={
                        props.logo
                          ? props.logo
                          : "https://titanesports.org:7000/static/media/6580d03bdb096d04c7ad7cf2.png"
                      }
                      className={`mx-10 w-16 h-16 md:w-20 md:h-20 object-cover rounded-full bg-visibility-primary p-2 border-2 border-background-tertiary`}
                    />
                  </td>
                  <td className={`text-left w-1/4 lg:w-1/2`}>{props.name}</td>
                  <td>
                    {`Series: `}
                    <br className={`md:hidden`} />
                    {`${props.series.wins} - ${props.series.losses}`}
                  </td>
                  <td>
                    {`Games: `}
                    <br className={`md:hidden`} />
                    {`${props.games.wins} - ${props.games.losses}`}
                  </td>
                  <td className={`${props.powerranking ? `` : `hidden`}`}>
                    {`Power Ranking: `}
                    <br className={`md:hidden`} />
                    {`${props.powerranking}`}
                  </td>
                </tr>
              </table>
              {props.eliminated ? (
                <div
                  className={`absolute w-full h-full pointer-events-none rounded flex items-center justify-center`}
                  style={{ backgroundColor: `rgba(255,0,0,0.1)` }}
                >
                  Eliminated
                </div>
              ) : null}
            </div>
          }
        />
      </div>
    );
  };
  React.useEffect(() => {
    const onScroll = () => {
      const n = 0.35 - window.scrollY * 0.0003;
      setOpacity(n > 0.035 ? n : 0.035);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [league, setLeague] = React.useState<any>(null);
  const Settings = fns.e(D, `D.getrecords_settings.init.records[0]`, null);
  React.useEffect(() => {
    if (fns.calls && fns.calls.getrecords_lolleague && !league) {
      const q = fns.readState().query;
      fns.calls.getrecords_lolleague({
        index: "league",
        search: { name: q.id },
      });
    }
  }, [fns.calls]);
  React.useEffect(() => {
    if (D && D.getrecords_lolleague && D.getrecords_lolleague.league) {
      setLeague(D.getrecords_lolleague.league.records[0]);
      if (fns.calls.getleaguestandings && !D.getleaguestandings) {
        fns.calls.getleaguestandings({
          index: "getleaguestandings",
          league: D.getrecords_lolleague.league.records[0]._id,
        });
      }
    }
  }, [D]);
  React.useEffect(() => {
    setLeague(null);
    delete D.getleaguestandings;
    const _f = fns.readState().query;
    if (!_f.id)
      fns.setQueryParams({
        id: "Conqueror League",
      });
  }, []);
  return D && fns.calls && Settings && league ? (
    <Page
      fns={fns}
      backgroundImage={{
        src: Settings.lolwebtheme.lolleaguepagesettings.backgroundimage,
        opacity,
      }}
    >
      <div className={`absolute top-0 w-full`}>
        <Header D={D} fns={fns} endpoint={endpoint} />
      </div>
      <FadeDivider img={league.img} ruby={league.logo} />
      <MissionStatement
        title={league.name}
        Message={<div>{JSON.parse(league.description).description}</div>}
      />
      {league.locked ? (
        <MissionStatement
          title={""}
          Message={
            <div
              className={`italic text-blue-200`}
            >{`${league.name} is now underway! Applications will be closed until the
          end of the current season.`}</div>
          }
        />
      ) : (
        <div />
      )}
      <div className={`mx-10`}>
        <HeroPanel
          single={mobile}
          index={diamonPickerIndex}
          pageCallback={setDiamondPickerIndex}
          small
          // @ts-ignore
          cards={[
            {
              hoverComponent: (
                <div className={`text-left`}>
                  <div className={`text-xl`}>
                    {JSON.parse(league.description).skillcap}
                  </div>
                </div>
              ),
              bgImg: JSON.parse(league.description).skillcap_graphic,
              subText: "Rank Cap",
              onClick: () => null,
            },
            {
              hoverComponent: (
                <div className={`text-left`}>
                  <img
                    className={`w-3/4 m-auto object-cover`}
                    src={league.logo}
                  />
                </div>
              ),
              bgImg: `https://titanesports.org:7000/static/media/6581153edb096d04c7ad8b7d.jpg`,
              subText: "Official League Document",
              onClick: () => fns.routeExternal(league.official_doc_url),
            },
            !league.locked
              ? {
                  hoverComponent: (
                    <div className={`text-center`}>
                      <div className={`text-xl`}>
                        Submit a Team Application!
                      </div>
                    </div>
                  ),
                  bgImg: `https://titanesports.org:7000/static/media/658114b5db096d04c7ad8b34.jpg`,
                  subText: "Team Applications",
                  onClick: () => fns.routeExternal(league.application_url),
                }
              : null,
          ].filter((el: any) => el)}
        />
      </div>
      <div
        className={`my-36 w-full flex-col justify-center space-y-10 text-center hidden`}
        style={{ marginTop: "130px", marginBottom: "130px" }}
      >
        <div className={`text-4xl`}>Tournament Bracket</div>
        <div className={`m-auto w-3/4 relative pointer-events-none`}>
          <div className={`${true ? `opacity-30` : ""}`}>
            <TournamentBracket />
          </div>
          {true ? (
            <div
              className={`absolute mt-10 w-44 lg:w-72 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-cover rounded-full bg-visibility-primary p-2`}
            >
              <FontAwesome animation="none" icon={"lock"} size="10xl" />
              <div>Bracket Locked Until Post-Season</div>
            </div>
          ) : null}
        </div>
      </div>
      {D.getleaguestandings &&
      D.getleaguestandings.records.filter((Team: any) => !Team.eliminated)
        .length === 1 ? (
        <div
          className={`flex justify-center items-center relative w-60 h-60 md:w-96 md:h-96 m-auto my-28 mb-60`}
        >
          <video
            src={`https://titanesports.org:7000/static/media/65812fccdb096d04c7ad967e.mp4`}
            className={`absolute h-full object-cover rounded-full border-2 border-background-tertiary`}
            autoPlay
            muted
            loop
          />
          <div className={`h-full absolute p-4 md:p-10`}>
            <img
              src={`https://titanesports.org:7000/static/media/worlds_cup.png`}
              className={`object-cover h-full`}
            />
          </div>
          <div
            className={`absolute bottom-0 text-md md:text-2xl bg-background-primary rounded-md border-2 border-background-tertiary w-full text-center p-1 md:p-5`}
          >
            <div className={`relative`}>
              Champions
              <div className={`text-xs md:text-sm`}>
                Season {D.getleaguestandings.seasonnum}
              </div>
            </div>
          </div>
          <div
            className={`-bottom-24 absolute text-3xl md:text-6xl w-screen text-center`}
          >
            {D.getleaguestandings.records[0].name}
          </div>
        </div>
      ) : (
        <div />
      )}
      {D.getleaguestandings && D.getleaguestandings.records.length ? (
        <div
          key={fns.readState().query}
          className={`my-36 w-full flex-col justify-center space-y-10 text-center`}
        >
          <div className={`text-4xl`}>League Standings</div>
          <div className={`text-2xl`}>
            Season {D.getleaguestandings.seasonnum} - Week{" "}
            {D.getleaguestandings.weeknum}
          </div>
          <div className={`m-auto w-full md:w-3/4`}>
            {D.getleaguestandings.records
              .filter((Team: any) => !Team.eliminated)
              .map((Team: any) => (
                <C
                  eliminated={Team.eliminated}
                  teamid={Team._id}
                  rank={Team.rank}
                  name={Team.name}
                  img={Team.img}
                  logo={Team.logo}
                  powerranking={Team.powerranking}
                  series={Team.series}
                  games={Team.games}
                />
              ))}
            {D.getleaguestandings.records.filter((Team: any) => Team.eliminated)
              .length ? (
              <div
                className={`flex justify-center items-center space-x-10 my-10`}
              >
                <div className={`h-[1px] w-1/3 bg-white`} />
                <div className={`border rounded-full w-3 h-3 bg-white`} />
                <div className={`h-[1px] w-1/3 bg-white`} />
              </div>
            ) : null}
            {D.getleaguestandings.records
              .filter((Team: any) => Team.eliminated)
              .map((Team: any) => (
                <C
                  eliminated={Team.eliminated}
                  teamid={Team._id}
                  rank={Team.rank}
                  name={Team.name}
                  img={Team.img}
                  logo={Team.logo}
                  powerranking={Team.powerranking}
                  series={Team.series}
                  games={Team.games}
                />
              ))}
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* <ListPanel
        key={league._id}
        controls={[
          {
            icon: "diamond",
            text: "name",
            key: "name",
          },
        ]}
        Request={{
          index: "content",
          type: "lolteam",
          search: {
            limit: 5,
            skip: 0,
            retired: { $ne: true },
            league: league._id,
          },
        }}
        fns={fns}
        D={D}
        card={(c: any) => ({
          img: c.img,
          subtext: c.name,
          onClick: () =>
            fns.routeExternal(
              `http://leagueoflegends.${
                process.env.REACT_APP_ENVIRONMENT === "production"
                  ? "titanesports.org"
                  : "localhost"
              }:3000/team?id=${c._id}`
            ),
        })}
        line
        constrain
      /> */}
      <Footer fns={fns} endpoint={endpoint} />
    </Page>
  ) : null;
};

export default League;
